(function () {
    'use strict';

    angular.module('informaApp')
        .component('pharmaProductsBar', {
            templateUrl: 'components/pharma-products-bar/template.html',
            controller: PharmaProductsBarController,
        });

    function PharmaProductsBarController(OmnitureSvc, ConstantsSvc) {
        const {baseUrl, others: subUrls} = ConstantsSvc.biomedtracker; 

        this.biomedtrackerLoginUrl = baseUrl + subUrls.login.url;

        this.onDMHealthcareClicked = () => {
            OmnitureSvc.topHeaderBar.trackExitToDMHealthCare();
        };

        this.onBiomedtrackerClicked = () => {
            OmnitureSvc.topHeaderBar.trackExitToBiomedtracker();
        };
    }
})();